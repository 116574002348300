.not-found{
  padding-top: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  .emoji{
    position: relative;
    width:15%;
  }
  img{
    object-fit: cover;
width: 100%;
  }
  .ey-root{
    position: absolute;
    border: 1px solid black;
    width: 74px;
    height: 83px;
    border-radius: 50%;
    span{
      position: absolute;
      width: 25px;
      height: 25px;
      background-color: black;
      border-radius: 50%;

    }
    &.left{
    top: 102px;
    left: 57px;
    }
    &.right{
      top: 101px;
      left: 158px;
    }
  }
}