.admin-nav {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  height: 100vw;
  background-color: #7b7b7b;
  transition: 0.2s;
  padding-top: 25px;
  border-right: 1px solid gray;

  .item {
    overflow: hidden;
    transition: 0.2s;
    background-color: #FFFFFF;

    a {
      font-size: 13px;
      transition: 0.2s;
      width: 100%;
      height: 100%;
    }

    &:hover {
      a {
        background-image: linear-gradient(#ff0000, #e7ff03);
      }
    }
  }

  &:hover {
    width: 184px;

    .item{
      margin: 10px;
      padding: 2px 15px;


      a {
        font-size: 20px;
      }
    }
  }
}

.admin-page {
  padding: 50px 5% 50px calc(5% + 50px);
  height: 100%;
}

.addBtn {
  padding: 10px 24px;
  background-color: greenyellow;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid green;
  border-radius: 4px;
  grid-column-gap: 8px;
  margin-top: 10px;
  cursor: pointer;
  transition: 0.2s;

  & > span {
    transition: 0.2s;

    &:first-of-type {
      background-color: #FFFFFF;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      border: 1px solid green;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &:hover {
    border: 1px solid greenyellow;
    background-color: green;
    box-shadow: 0 2px 10px green;
    color: #FFFFFF;
    & > span {
      &:first-of-type {
        border-color: greenyellow;
      }

      &:last-of-type {
        color: #FFFFFF;
      }
    }
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  grid-column-gap: 15px;
  margin-right: 25px;

  button {
    padding: 5px 24px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;

    &:first-of-type {
      background-color: #00baff96;
      border: 1px solid darkblue;

      &:hover {
        box-shadow: 0 2px 10px darkblue;
        background-color: darkblue;
        color: #FFFFFF;
        border-color: #00baff96;
      }
    }

    &:last-of-type {
      background-color: #f76222;
      border: 1px solid #630101;

      &:hover {
        box-shadow: 0 2px 10px #630101;
        background-color: #630101;
        border-color: #f76222;
        color: #FFFFFF;
      }
    }
  }
}