*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  font-family: sans-serif;
}

a{
  text-decoration: none;
}

body {
  background-color: #f5f5f5;
  position: relative;
}

#root{
  height: 100%;
}

.App {
  height: 100%;
}

.page {
  padding: 0 2.5%;
  height: 100%;
}

.page-main {
  padding: 50px 2.5%;
  min-height: 100%;
}

.main-container {
  height: 100%;
}


.loading {
  position: fixed;
  top: 0;
  left: 0;
  border: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(110, 123, 136, 0.56);
  z-index: 9999;
  backdrop-filter: blur(10px);

  .MuiCircularProgress-root {
    width: 7.5vw !important;
    height: 7.5vw !important;
    color: rgb(0, 255, 187) !important;
  }
}

.text-blue-green {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#5bff00, #0078ff);
}

.text-red-yellow {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#ff0000, #e7ff03);
}

.s-b{
  display: flex;
  justify-content: space-between;
}

.error-message{
  color: red;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .page-main{
    padding: 50px 0;
  }
}