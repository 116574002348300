.product-details-main {
  display: flex;
  justify-content: center;
  grid-column-gap: 10%;

  .image-area {
    width: 450px;
    height: 450px;
    overflow: hidden;
    position: relative;

    .wish-icon {
      cursor: pointer;
      position: absolute;
      z-index: 9;
      top: 10px;
      right: 10px;

      &.active {
        svg path {
          fill: red;
          stroke: red;
        }
      }

      svg {
        width: 40px;
        stroke: gray;
      }

      &:hover {
        svg {
          stroke: #f76222;
        }
      }
    }

    img {
      width: 100%;
      object-fit: contain;
      transition: 0.25s;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .details {
    min-width: 450px;
    max-width: 600px;

    .rating-product {
      margin: 25px 0 25px;
    }

    .descripion {
      h2 {
        margin: 25px 0 15px;
      }
    }

    .add-product-to-cart {
      margin-top: 30px;

      .qtt-area {
        grid-column-gap: 15px;

        input {
          width: 50px;
          height: 40px;
          margin-left: 15px;
          text-align: center;
        }
      }

      .add-to-cart-btn {
        margin-top: 30px;
        height: 48px;

        > span {
          color: #FFFFFF !important;
          width: 25px !important;
          height: 25px !important;
        }
      }
    }
  }
}

.related-products {
  padding: 96px 10% 50px;

  h2 {
    margin-bottom: 25px;
  }

  .slick-initialized .slick-slide {
    padding-left: 10px;
  }

  .product-item {
    margin: 15px 0;
  }

  .slick-arrow {
    background-color: #b5feeb;
    border-radius: 50%;
    width: 50px;
    height: 50px;

    &::before {
      font-size: 32px;
      top: 15px;
    }

    &.slick-prev {
      left: -75px;
    }

    &.slick-next {
      right: -75px;
    }
  }
}

.price-sale {
  display: flex;
  align-items: center;
  grid-column-gap: 15px;

  h1 {
    font-size: 24px;
    color: #1159b7;
  }

  span {
    font-size: 14px;
    color: gray;
    text-decoration: line-through;
  }
}

.product-price {
  font-size: 24px;
  color: #1159b7;
}

@media screen and (max-width: 1024px) {
  .related-products{
    padding: 36px 5% 50px;;
  }
  .product-details-main{
    .details{
      min-width: 250px;
    }
  }

}
@media screen and (max-width: 768px) {
  .related-products{
    padding: 0;;
  }
  .product-details-main{
    flex-wrap: wrap;
    .details{
      width: 100%;
      margin-bottom: 30px;
    }
  }

}