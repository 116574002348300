.rate-area {
  display: flex;
  grid-column-gap: 5px;
  margin-top: 10px;

  .rating-star {
    cursor: pointer;
    width: 15px;
    height: 15px;

    svg {
      width: 15px;
      height: 15px;
      stroke: black;
      fill: #FFFFFF;
    }

    &.active {
      svg {
        fill: orange;
      }
    }
  }
}

.standart-btn {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: rgb(2, 139, 120);
  color: #FFFFFF;
  cursor: pointer;
  border: none;

  &:disabled {
    opacity: 0.5;
  }
}

.main-banner {
  //display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 25vw;
  //margin: 15px 20px 0;
  margin-top: 15px;
  border-radius: 20px;

  .slick-slider {

    .slick-list {

      .slick-track {

        .slick-slide {
          padding: 0 20px;

          > div {
            position: relative;
            //width: calc(100vw - 57px);
            height: 25vw;
            background-color: rgba(0, 0, 0, 0.7);
            border-radius: 20px;
            overflow: hidden;
          }
        }
      }
    }
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 20px;

    video {
      border-radius: 20px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .image {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .item {
    .text-area {
      position: absolute;
      left: 10%;
      top: 25%;
      max-width: 45%;

      h1 {
        color: #FFFFFF;
        margin-bottom: 20px;
        font-size: 4vw;
      }

      p {
        color: #FFFFFF;
        margin-bottom: 15px;
        font-size: 2vw;
      }

      button {
        width: 250px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main-banner {
    height: 50vw;
    min-height: 250px;

    .slick-slider .slick-list .slick-track .slick-slide > div {
      min-height: 250px;
      height: 50vw;
      .text-area{
        max-width: 80%;
        h1{
          font-size: 6vw;
        }
        p{
          font-size: 4vw;
        }
      }
      .item {
        height: 100%;

        .image {
          height: 100%;

          img {
            width: unset;
            height: 100%;
            object-fit: cover;
          }
        }

      }

    }
  }
}

@media screen and (max-width: 450px) {
  .main-banner {
    height: 86vw;
    min-height: 250px;
    margin-top: 10px;

    .slick-slider .slick-list .slick-track .slick-slide {
      padding: 0 10px;

      > div {
        min-height: 250px;
        height: 86vw;
        .text-area{
          h1{
            font-size: 7vw;
          }
          p{
            font-size: 5vw;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
