.auth-dialog{
  .MuiPaper-root{
    .close-icon{
      display: none;
    }
  }
}

.auth-tabs{
  margin: 25px 50px 0;
  display: flex;
  justify-content: space-between;
  button{
    width: 50%;
    padding: 10px;
    border: none;
    background-color: #FFFFFF;
    cursor: pointer;
    transition: 0.25s;
    color: #08413b;
    &.active{
      border-bottom: 3px solid rgb(2, 139, 120);
    }
    &:hover{
      background-color: rgba(2, 139, 120, 0.3);
    }
  }
}

.login-reg {
  padding: 25px 50px 50px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 25px;



  > div {
    width: 240px;
  }

  input, select {
    height: 48px;
    width: 240px;
    padding: 10px;
  }

  button {
    height: 48px;
    background-color: blue;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    color: #FFFFFF;
  }
}

.top-panel {
  padding: 10px 5%;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  a, span {
    color: #FFFFFF;
    font-width: bold;
  }
}

header {
  height: 76px;
  margin: 10px 10%;
  padding: 0 2.5%;
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: #08413b;
  position: sticky;
  top: 15px;
  left: 0;
  right: 0;
  z-index: 99;
  .mobile-search{
    display:none ;
  }

  .search-area {
    width: 30%;
    min-width: 350px;
    height: 46px;

    form {
      height: 100%;
      border-radius: 10px;
      background-color: #FFFFFF;
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      position: relative;

      .reset-search {
        position: absolute;
        top: 10px;
        right: 50px;
        cursor: pointer;

        svg {
          width: 25px;
          stroke: #08413b;
        }

        &:hover {
          svg {
            stroke: #ff0000;
          }
        }
      }

      input {
        width: 90%;
        height: 100%;
        border: none;
        padding: 15px;
        font-size: 18px;
      }

      button {
        width: 48px;
        height: 100%;
        border: none;
        background-color: #ffe495;
        transition: 0.25s;
        cursor: pointer;

        svg {
          width: 25px;
          stroke: #08413b;
        }

        &:hover {
          background-color: #d7c387;
        }
      }
    }
  }

  .header-actions {
    display: flex;
    width: 17%;
    justify-content: end;
    grid-column-gap: 25px;
    align-items: center;

    .header-icon-buttons {
      display: flex;
      align-items: center;
      grid-column-gap: 15px;

      .search-icon-mobile {
        display: none;
      }

      .user-icon, .signin-icon, .basket-icon {
        cursor: pointer;
        position: relative;

        svg {
          width: 30px;
          fill: #ffe495;
        }

        span {
          position: absolute;
          top: -7px;
          right: -10px;
          padding: 1px 5px;
          border-radius: 50%;
          background-color: red;
          color: #FFFFFF;
          font-size: 12px;
        }
      }
    }

    .is-logged-in {
      display: flex;
      grid-column-gap: 25px;
      align-items: center;

      .say-hi-user {
        display: flex;
        grid-column-gap: 15px;
        padding: 5px 15px;
        border-radius: 10px;
        background: linear-gradient(0deg, rgb(180 222 241) 0%, rgb(179 179 179) 100%);
        box-shadow: 0 0 15px 5px grey;
      }
    }
  }

  .login-reg-btns {
    display: flex;
    grid-column-gap: 25px;

    > div {
      cursor: pointer;

    }
  }
}

.header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-column-gap: 25px;
  min-width: 12%;

  .nav-items{
    display: flex;
    grid-column-gap: 15px;
  }

  .nav-logo {
    img {
      height: 50px;
    }
  }

  > div {
    span {
      cursor: pointer;
      font-weight: bold;
      color: #FFFFFF;

      &:hover {
        text-shadow: 0px 0px 10px #ffffff;
      }
    }
  }
}

.user-popover {
  .MuiPaper-root {
    width: 250px;
    left: unset !important;
    right: 9%;
  }

  .user-list {
    width: 250px;
    background-color: #FFFFFF;
    border-radius: 10px;

    li {
      display: flex;
      padding: 20px 10px;
      border-bottom: 1px solid #dcdcdc;
      align-items: center;
      grid-column-gap: 20px;
      cursor: pointer;

      &:last-of-type {
        svg {
          transform: rotate(180deg);
        }
      }

      &:hover {
        background-color: rgba(255, 228, 149, 0.53);
      }

      a {
        display: flex;
        align-items: center;
        grid-column-gap: 20px;
        text-decoration: none;
      }

      p {
        color: darkslategray;
      }

      svg {
        width: 20px;
        fill: #a39361;
        stroke: #a39361;
      }
    }
  }
}

.categories-drower {
  display: flex;
  padding: 35px;
  min-height: 355px;

  .categories-area {
    min-width: 225px;
    height: 100%;
    min-height: 355px;
  }

  .subCategories-area {
    padding-left: 35px;
    border-left: 2px solid gray;
    min-height: 355px;
    display: flex;
    grid-column-gap: 10%;
    width: 100%;
    flex-wrap: wrap;

    .subCategory-area {
      display: flex;
      flex-direction: column;
      grid-row-gap: 15px;

      .subcategory > a {
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(#5bff00, #0078ff);
        text-decoration: none;
        font-size: 20px;
      }
    }

    .subSubCategories {
      display: flex;
      flex-direction: column;
      grid-row-gap: 15px;

      a {
        text-decoration: none;
        font-weight: 100;
        color: #000000;
      }
    }
  }

  .category-item {
    font-weight: bold;
    font-size: 20px;
    margin: 5px;
    padding: 2px 15px;
    border-radius: 3px;

    a {
      text-decoration: none;
      color: black;
    }

    p {
      color: black;
    }

    &.active {
      background-color: #2196f33d;

      a, p {
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(#ff0000, #e7ff03);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  header {
    .desctop-search{
      display: none;
    }

    .mobile-search{
      display: block;
      position: absolute;
      top: 91px;
      left: 0;
      width: 100%;
      .search-area{
        width: 100%;
        form{
          border-bottom: 2px solid #ffe495;
          input{
            font-size: 16px;
          }
        }
      }

      .line{
        position: absolute;
        width: 95%;
        left: 2.5%;
        bottom: -1px;
        z-index: -1;
        background-color: #ffe495;
        height: 1px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }
      .close-mobile-search{
        display: flex;
        justify-content: center;
        margin-top: 10px;
        position: absolute;
        width: 40px;
        height: 40px;
        left: calc(50% - 15px);
        background-color: #ffe495;
        cursor: pointer;
        border-radius: 0% 50% 50% 50%;
        transform: rotate(45deg);
        svg{
          width: 30px;
          transform: rotate(45deg);
          stroke: #08413b;
        }
      }
    }

    .header-actions {
      .header-icon-buttons {
        .search-icon-mobile {
          display: block;
          cursor: pointer;
          svg {
            width: 30px;
            height: 30px;
            stroke: #ffe495;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  header{
    margin: 10px 20px;
    padding: 0 4%;
  }
}
@media screen and (max-width: 450px) {
  .auth-dialog{
    .MuiPaper-root{
      width: 100%;
      max-width: 100%;
      margin: 0;
      height: 100%;
      max-height: 100%;
      display: flex;
      justify-content: center;

      .close-icon{
        display: block;
        position: absolute;
        top: 25px;
        right: 25px;
        >svg{
          width: 30px;
          stroke: #08413b;
        }
      }
      .login-reg{
        >div, input{
          width: 100%;
        }
      }
    }
  }
  .top-panel{
    margin: 0 10px;
    a, span{
      font-size: 14px;
    }
  }
  header{
    margin: 10px ;
    top: 10px;
    .header-nav{
      .burger{
        svg{
          width: 30px;
          fill: #ffe495;
        }
      }
    }
    .mobile-search{
      top: 86px;
    }
  }
}