.admin-categories {
  height: 100%;
}

.categories-container {
  padding: 50px 2.5%;
  background-color: #FFFFFF;
  height: 100%;
  margin-top: 25px;
  border-radius: 2px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 5%);

  .categoriItem {
    & .title {
      padding: 5px;
    }

    &.c1 {
      padding-left: 50px;
    }

    &.c2 {
      padding-left: 50px;
    }

    .single-category {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      background-color: rgba(131, 220, 202, 0.36);
      border: 1px solid #cecece;
      border-radius: 2px;
      margin: 5px 0;
      transition: 0.2s;
      height: 40px;

      &:hover {
        background-color: rgb(131 220 202 / 26%);
        box-shadow: 0 0px 5px green;
      }
    }


  }
}