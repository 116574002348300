.page-main.personal-page {
  padding: 100px 8%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  grid-column-gap: 5%;

  ul {
    width: 30%;
    max-width: 350px;
    min-width: 275px;
    position: sticky;
    top: 106px;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      border-radius: 10px;
      grid-column-gap: 20px;
      cursor: pointer;
      background-color: #e7e7e7;
      transition: 0.25s;

      &:last-of-type {
        padding: 30px 20px;

        svg {
          transform: rotate(180deg);
        }
      }

      &:hover {
        box-shadow: 0px 0px 14px #08413b;
        transform: scale(1.01);
      }

      &.active {
        background-color: #08413b;

        p {
          color: #FFFFFF;
        }

        svg {
          fill: #ffe495;
          stroke: #ffe495;
        }
      }

      a {
        width: 100%;
        height: 100%;
        padding: 30px 20px;
        display: flex;
        align-items: center;
        grid-column-gap: 20px;
        text-decoration: none;

      }

      p {
        color: darkslategray;
        font-weight: bold;
      }

      svg {
        width: 20px;
        fill: #a39361;
        stroke: #a39361;
      }
    }
  }

  .personal-right {
    width: 75%;

    .personal-item {
      display: flex;
      grid-column-gap: 10%;
    }

    .wish-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
      grid-gap: 15px;
    }
  }
}

@media screen and(max-width: 1250px) {
  .page-main.personal-page {
    .personal-right {
      .wish-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(184px, 1fr));
      }

    }


  }
}

@media screen and(max-width: 1024px) {
  .page-main.personal-page {
    flex-direction: column;
    padding: 50px 0;
    >a{
      margin-bottom: 30px;
    }
    .personal-right{
      width: 100%;
    }
    .user-list.mobile {
      width: 100%;
      max-width: 100%;
    }
  }
}

@media screen and(max-width: 768px) {
  .page-main.personal-page {
    padding: 50px 0;

  }
}
@media screen and(max-width: 450px) {
  .page-main.personal-page {
    padding: 50px 0;
    .personal-right {
      .wish-list {
        grid-template-columns: repeat(auto-fill, minmax(144px, 1fr));
      }

    }
  }
}