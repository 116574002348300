.admin-products-container {
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    thead {
      tr {
        border-bottom: 1px solid #d4d4d4 ;
        //background-color: #ededed;
        background: rgb(40,30,120);
        background: linear-gradient(0deg, #f7f7f7 0%, #d3d3d3 100%);
        th {
          text-align: start;
          padding: 10px;
          &:last-of-type{
            text-align: center;
          }
        }
      }
    }

    tr.single-product {
      width: 100%;
      //background-color: rgba(131, 220, 202, 0.36);
      border-bottom: 1px solid #d4d4d4;
      border-radius: 2px;
      margin: 5px 0;
      transition: 0.2s;
      width: 100%;

      &:hover {
        //background-color: rgb(131 220 202 / 26%);
        box-shadow: 0 0px 5px green;
      }

      td {
        padding: 5px;
        img {
          width: 60px;
        }

        &:last-of-type {
          max-width: 62px;
          padding-left: 15px;
        }
      }
    }
  }
}