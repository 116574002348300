.basket-main {
  .content {
    display: flex;
    justify-content: space-between;
    grid-column-gap: 5%;
    padding: 0 10%;
    align-items: flex-start;

    .container {
      width: 70%;
    }
  }
}

.cart-item {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 5px cadetblue;
  padding: 20px 30px;
  margin-bottom: 25px;
  border-radius: 5px;
  position: relative;

  .cart-item-loader {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgb(2 139 120 / 15%);
    backdrop-filter: blur(10px);

    .MuiLinearProgress-root {
      background-color: rgba(2, 139, 120, 0.26);

      .MuiLinearProgress-bar {
        background-color: rgb(2, 139, 120);

      }
    }
  }

  .image-area {
    width: 10%;

    img {
      width: 100%;
    }
  }

  .title-desc {
    width: 70%;

    .price-sale {
      margin-top: 10px;
    }
  }

  .actions {
    width: 10%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .icon-btns {
      display: flex;
      grid-column-gap: 15px;
      align-items: center;

      .delete-icon {
        cursor: pointer;
        svg {
          width: 20px;
          fill: gray;
        }
        &:hover{
          svg{
            fill: red;
          }
        }
      }

      .wish-icon {
        cursor: pointer;

        &.active {
          svg path {
            fill: red;
            stroke: red;
          }
        }

        svg {
          width: 20px;
          stroke: gray;
        }

        &:hover {
          svg {
            stroke: #f76222;
          }
        }
      }
    }

    .change-qtt {
      display: flex;
      justify-content: space-between;
      align-items: center;
      grid-column-gap: 10px;
      margin-top: 15px;

      span {
        padding: 0 7px;
        background-color: #dcdcdc;
        color: #414141;
        border-radius: 50%;
        cursor: pointer;
        font-size: 20px;

        &:hover:not(.disabled) {
          background-color: gray;
          color: #FFFFFF;
        }

        &.disabled {
          opacity: 0.25;
          cursor: not-allowed;
        }
      }

      p {
        color: #51a1ff;
        font-size: 20px;
      }
    }
  }
}

.summary {
  box-shadow: 0 0 5px cadetblue;
  padding: 20px 30px;
  margin-bottom: 25px;
  border-radius: 5px;
  width: 25%;
  min-width: 250px;
  min-height: 144px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and(max-width: 1250px) {
  .cart-item{
    .image-area{
      width: 15%;
    }
    .title-desc{
      width: 50%;
    }
  }
}

@media screen and(max-width: 1024px) {
  .basket-main{
    .content{
      padding: 0;
    }
  }
  .cart-item{
    padding: 15px 20px;
    .image-area{
      width: 20%;
    }
  }
  .summary{
    padding: 15px 20px;
  }
}

@media screen and(max-width: 768px) {
  .basket-main{
    .content{
      flex-wrap: wrap;
      .container{
        width: 100%;
      }
    }
  }
  .summary{
    width: 100%;
    margin-top: 30px;
  }
}