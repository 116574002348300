.page-main.products{
  padding:50px 10%;
}

.products-container {
  display: flex;
  grid-column-gap: 2%;
  padding: 50px 0;
}

.product-item {
  max-width: 250px;
  min-width: 150px;
  width: 100%;
  box-shadow: 0px 0px 15px 0px #b3b3b3;
  border-radius: 5px;
  height: 400px;
  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .image-area {
    width: 100%;
    height: 248px;
    overflow: hidden;
    border-radius: 5px 5px 0 0 ;
    img {
      width: 100%;
      transition: 0.25s;
      object-fit: cover;
    }

  }

  &:hover {
    .image-area {
      img{

        transform: scale(1.1);
      }
    }
  }

  .details {
    padding: 10px;
    height: 33%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      display: flex;
      grid-column-gap: 10px;
      align-items: center;
      margin-top: 8px;

      span {
        &:first-of-type {
          color: #004397;
          font-weight: bold;
          font-size: 20px;
        }

        &:last-of-type {
          color: gray;
          text-decoration: line-through;
          font-size: 14px;
        }
      }
    }
  }
}

@media screen  and(max-width: 768px){
  .product-item{
      height: 360px;
    .image-area{
      height: 184px;
    }
  }
}